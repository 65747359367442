import { Link } from "react-router-dom";
import mockup from "./mockup.png";
import badge from "./google-play-badge.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="section description">
        <h1>Little Happiness</h1>
        <p>
          Discover moments of joy with Little Happiness, our mobile app designed
          to help you cherish daily happiness. Immerse yourself in a calendar
          view that navigates through your happy memories effortlessly.
        </p>
        <p>
          Plus, experience the delightful surprise of unveiling past moments of
          happiness whenever you need a boost.
        </p>
        <div className="button-group">
          <a href="https://play.google.com/store/apps/details?id=app.meworks.little_happiness">
            <img src={badge} alt="Get it on Google Play" width="200" />
          </a>
          <button
            data-tally-open="3EPBMX"
            data-tally-layout="modal"
            data-tally-emoji-text="👋"
            data-tally-emoji-animation="wave"
            data-tally-auto-close="0"
          >
            Sign up for Alpha Testing
          </button>
        </div>

        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <div className="section mockup-parent">
        <img src={mockup} className="mockup" alt="mockup" />
      </div>
    </div>
  );
}

export default App;
