import privacy from "./privacy.json";
import "./Privacy.css";

function Privacy() {
  return (
    <div id="privacy-policy">
      <h1>{privacy.title}</h1>
      <p>{privacy.content}</p>
      {privacy.sections.map((section) => (
        <>
          <h2>{section.header}</h2>
          <p>{section.content}</p>
        </>
      ))}
      <p>Last Updated at: {privacy.last_updated}</p>
    </div>
  );
}

export default Privacy;
